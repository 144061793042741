<template>
  <div
    class="text-field-input-root"
    :class="[
      {
        'text-field-input-root--hasShadow': hasShadow,
        'text-field-input-root--noRightBorder': noRightBorder,
      },
    ]"
    @click="handleClick"
  >
    <div class="TextFieldInput_label"
         :class="[{
      'TextFieldInput_label--bold': bold,
      'TextFieldInput_label--large': large,
      }]">
      <label
        v-if="label"
        :for="name"
        class="block mb-[10px] text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white text-left 2xl:text-[14px] 2xl:leading-[16px]"
      >
        {{ label }}
        <span
          class="requireStar"
          v-if="requireStar"
        >
          *
        </span>
      </label>
      <span
        v-if="rightLabelValue.length"
        class="TextFieldInput_rightLabelString"
      >
        {{ rightLabelString }}
        <span class="TextFieldInput_rightLabelValue">{{ rightLabelValue }}</span>
        <div
          v-if="rightLabelFee"
          class="TextFieldInput_rightValueFee">
          ({{ rightLabelFee.toFixed( 2 ) }})
        </div>
      </span>
    </div>
    <div :class="{
      'flex': withButton,
      'items-start': withButton,
    }">
      <v-text-field
        :class="customStylesTextFieldInput"
        :autocomplete="autocomplete"
        :error="error"
        :value="value"
        :name="name"
        :label="placeholder"
        :type="computeType"
        :placeholder="placeholderValue"
        dark
        v-maska="mask ? mask : ''"
        solo
        dense
        :rules="rules"
        :hide-details="hideDetails"
        :append-icon="computedAppendIcon"
        @click:append="inputVisibility = !inputVisibility"
        @input="handleInput"
        :disabled="disabled"
        validate-on-blur
        :maxlength="maxlength"
      >
        {{ value }}
        <template #append
                  v-if="dropdownArrow">
          <i class="material-icons TextFieldInput_dropdownArrow"
             :class="{
          'TextFieldInput_dropdownArrow--active': dropdownActive,
        }">arrow_drop_down</i>
        </template>
      </v-text-field>
      <Button
        v-if="withButton"
        :class="[
          '!min-w-[120px]', 'h-[46px]', 'btn-bright-light min-w-[100px] text-[12px]'
        ]"
        :text="btnText"
        @click="onClickBtn"
      />
    </div>
  </div>
</template>

<script>
import Button from "../Button/Button"
export default {
  components: { Button },
  props: {
    customStylesTextFieldInput: {
      type: String
    },
    dropdownArrow: {
      type: Boolean,
      default: false,
    },
    dropdownActive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    passwordInput: {
      type: Boolean,
      default: false,
    },
    searchInput: {
      type: Boolean,
      default: false,
    },
    hasShadow: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    noRightBorder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderValue: {
      type: String,
      default: '',
    },
    rightLabelString: {
      type: String,
      default: '',
    },
    rightLabelValue: {
      type: String,
      default: '',
    },
    rightLabelFee: {
      type: Number,
      default: null,
    },
    hideDetails: {
      type: [ String, Boolean ],
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    removeSpacesHandler: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
    },
    mask: {
      type: String,
      default: '',
    },
    requireStar: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 150
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    btnText: {
      type: String,
      default: "",
    },
    withButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputVisibility: false,
    };
  },
  computed: {
    computeType() {
      if (this.type === 'password') {
        if (this.inputVisibility) {
          return 'text';
        }
        return this.type;
      }
      return this.type;
    },
    computedAppendIcon() {
      if (this.passwordInput) {
        if (this.inputVisibility) {
          return 'visibility_off';
        }
        return 'visibility';
      }
      if (this.searchInput) {
        return 'search';
      }
      if (this.appendIcon) {
        return this.appendIcon;
      }
      return '';
    },
  },
  methods: {
    handleInput(value) {
      if (this.removeSpacesHandler || this.mask) {
        this.$emit('input', value.replace(/\s/g, ''));
      } else {
        this.$emit('input', value);
      }
    },
    handleClick() {
      this.$emit('click');
    },
    onClickBtn() {
      this.$emit( "clickBtn" );
    }
  },
};
</script>

<style lang="scss">
@import './TextFieldInput.scss';
</style>
