<template>
  <div class="CurrencyForm_root">
    <div class="CurrencyForm_container">
      <div class="CurrencyForm_wrapper">
        <div class="CurrencyForm_septum">provider fees and limits</div>
        <div class="CurrencyForm_box" v-if="feesComputed.deposit">
          <div class="CurrencyForm_title">Deposit</div>
          <div class="CurrencyForm_fields">
            <TextFieldInput
              class="CurrencyForm_textfield"
              :value="feesComputed.deposit.fee.percent_fee + ' %'"
              name="percent_fee"
              label="Percent fee"
              disabled
              hideDetails
            />
            <TextFieldInput
              class="CurrencyForm_textfield"
              :value="feesComputed.deposit.fee.static_fee + ` ${currency}`"
              name="static_fee"
              label="Static fee"
              disabled
              hideDetails
            />
          </div>
        </div>
        <div class="CurrencyForm_box" v-if="feesComputed.withdrawal">
          <div class="CurrencyForm_title">Withdrawal</div>
          <div class="CurrencyForm_fields">
            <TextFieldInput
              class="CurrencyForm_textfield"
              :value="feesComputed.withdrawal.fee.percent_fee + ' %'"
              name="percent_fee"
              label="Percent fee"
              disabled
              hideDetails
            />
            <TextFieldInput
              class="CurrencyForm_textfield"
              :value="feesComputed.withdrawal.fee.static_fee + ` ${currency}`"
              name="static_fee"
              label="Static fee"
              disabled
              hideDetails
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';

export default {
  components: {
    TextFieldInput,
  },
  props: {
    paymentWays: {
      type: Object,
      default: () => {},
    },
    currency: {
      type: String,
      default: '',
    },
  },
  computed: {
    feesComputed() {
      return this.paymentWays;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.CurrencyForm_container {
  background: #2d4d70;
  border: 2px solid #213b57;
  box-sizing: border-box;
  box-shadow: 0px 16.4937px 31.1548px rgba(34, 39, 47, 0.55);
  border-radius: 4px;
}

.CurrencyForm_wrapper {
  padding: 13px 17px;
}
.CurrencyForm_septum {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  padding-bottom: 9px;
  margin: 14px 0;
}

.CurrencyForm_title {
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  margin-bottom: 10px;
}

.CurrencyForm_fields {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.CurrencyForm_textfield {
  flex: 0 1 calc(50% - 5px);
}

.CurrencyForm_btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 13px 0;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
}

.CurrencyForm_btn {
  flex: 0 1 calc(50% - 5px);
}
</style>
