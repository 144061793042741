<template>
  <section class="DetailsProvider_root">
    <AppSection title="Provider Details">
      <div slot="tollbar">
        <RoundButton class="CreateAndUpdateProvider_backBtn" withIcon iconType="arrowLeft" text="back to dashboard" @click="goBack" />
      </div>
      <div class="CreateAndUpdateProvider_wrapper" slot="content">
        <div class="providerDetailsItem" v-for="(item, key) in providerComputed" :key="key">
          <TextFieldInput :value="key" label="Name" disabled name="name" />
          <!-- {{ item.payment_ways.default }} -->
          <div class="CreateAndUpdateProvider_currency-forms">
            <DetailsForm :currency="$route.params.id" :paymentWays="item.payment_ways.default" />
          </div>
          <!-- <div v-if="dynamicFields.length">
            <div v-for="field in dynamicFields" :key="field.name">
              <TextFieldInput
                :value="dynamicFieldsForm[field.name]"
                :name="field.name"
                placeholder=""
                :rules="field.is_required ? [...inputRules.required] : []"
                :label="field.description"
                @input="handleDynamicFieldInput(arguments[0], field.name)"
              />
            </div>
          </div> -->
        </div>
      </div>
    </AppSection>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';
import AppSection from '@/components/_common/AppSection/AppSection';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import DetailsForm from './DetailsForm/DetailsForm';
export default {
  components: {
    AppSection,
    RoundButton,
    TextFieldInput,
    DetailsForm,
  },
  data: () => ({}),
  computed: {
    ...mapState('providers', ['userProviders']),
    providerComputed() {
      return this.userProviders[this.$route.params.id];
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'providers.dashboard' });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.DetailsProvider_root {
  .CreateAndUpdateProvider_wrapper {
    max-width: 65%;
  }

  .CurrencyForm_box {
    margin: 0 0 15px 0;
  }

  .CurrencyForm_wrapper {
    .CurrencyForm_box {
      &:last-child {
        margin: 0;
      }
    }
  }

  .providerDetailsItem {
    margin: 0 0 24px 0;
  }

  .CreateAndUpdateProvider_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;
  }

  .CreateAndUpdateProvider_select {
    margin-bottom: 15px;
  }

  .CreateAndUpdateProvider_checkbox-label {
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-right: 50px;
  }

  .CreateAndUpdateProvider_septum {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    border-bottom: 1px solid rgba(#2af3f3, 0.1);
    padding-bottom: 9px;
  }

  .CreateAndUpdateProvider_add-btn {
    margin: 16px 0;
  }

  .CreateAndUpdateProvider_currency-forms {
    padding: 0 24px;
  }

  .CreateAndUpdateProvider_footer-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .CreateAndUpdateProvider_footer-btn {
      flex: 0 1 31%;
    }
  }
}
</style>
