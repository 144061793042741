var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-field-input-root",class:[
    {
      'text-field-input-root--hasShadow': _vm.hasShadow,
      'text-field-input-root--noRightBorder': _vm.noRightBorder,
    } ],on:{"click":_vm.handleClick}},[_c('div',{staticClass:"TextFieldInput_label",class:[{
    'TextFieldInput_label--bold': _vm.bold,
    'TextFieldInput_label--large': _vm.large,
    }]},[(_vm.label)?_c('label',{staticClass:"block mb-[10px] text-[12px] leading-[14px] uppercase text-gray-600 dark:text-white text-left 2xl:text-[14px] 2xl:leading-[16px]",attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.requireStar)?_c('span',{staticClass:"requireStar"},[_vm._v(" * ")]):_vm._e()]):_vm._e(),(_vm.rightLabelValue.length)?_c('span',{staticClass:"TextFieldInput_rightLabelString"},[_vm._v(" "+_vm._s(_vm.rightLabelString)+" "),_c('span',{staticClass:"TextFieldInput_rightLabelValue"},[_vm._v(_vm._s(_vm.rightLabelValue))]),(_vm.rightLabelFee)?_c('div',{staticClass:"TextFieldInput_rightValueFee"},[_vm._v(" ("+_vm._s(_vm.rightLabelFee.toFixed( 2 ))+") ")]):_vm._e()]):_vm._e()]),_c('div',{class:{
    'flex': _vm.withButton,
    'items-start': _vm.withButton,
  }},[_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.mask ? _vm.mask : ''),expression:"mask ? mask : ''"}],class:_vm.customStylesTextFieldInput,attrs:{"autocomplete":_vm.autocomplete,"error":_vm.error,"value":_vm.value,"name":_vm.name,"label":_vm.placeholder,"type":_vm.computeType,"placeholder":_vm.placeholderValue,"dark":"","solo":"","dense":"","rules":_vm.rules,"hide-details":_vm.hideDetails,"append-icon":_vm.computedAppendIcon,"disabled":_vm.disabled,"validate-on-blur":"","maxlength":_vm.maxlength},on:{"click:append":function($event){_vm.inputVisibility = !_vm.inputVisibility},"input":_vm.handleInput},scopedSlots:_vm._u([(_vm.dropdownArrow)?{key:"append",fn:function(){return [_c('i',{staticClass:"material-icons TextFieldInput_dropdownArrow",class:{
        'TextFieldInput_dropdownArrow--active': _vm.dropdownActive,
      }},[_vm._v("arrow_drop_down")])]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.value)+" ")]),(_vm.withButton)?_c('Button',{class:[
        '!min-w-[120px]', 'h-[46px]', 'btn-bright-light min-w-[100px] text-[12px]'
      ],attrs:{"text":_vm.btnText},on:{"click":_vm.onClickBtn}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }